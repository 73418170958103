export class UrlObjectManager {
    private static fileStore: Record<string, string> = {};
    static clearObjects() {
        Object.values(UrlObjectManager.fileStore).map((obj) => URL.revokeObjectURL(obj));
        UrlObjectManager.fileStore = {};
    }

    static registerObject(fileUrl: string, ObjectUrl: string) {
        UrlObjectManager.fileStore[fileUrl] = ObjectUrl;
    }

    static getUrl(fileUrl: string) {
        return UrlObjectManager.fileStore[fileUrl] || fileUrl;
    }
}
