import React, { useEffect, useState } from 'react';

import { DocSource } from '@/utils/types';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    HStack,
    Link,
    Popover,
    PopoverAnchor,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    Tag,
    Text,
    useDisclosure,
} from '@chakra-ui/react';

export function MessageSources({ sources }: { sources: DocSource[] }) {
    const initialFocusRef = React.useRef<HTMLButtonElement>(null);
    const tagRowRef = React.useRef<HTMLDivElement>(null);
    const { isOpen, onToggle, onClose } = useDisclosure();
    const [anchorIndex, setAnchorIndex] = useState(0);
    const [sourceIndex, setSourceIndex] = useState(0);
    const [showAll, setShowAll] = useState(false);
    const [hasOverflow, setHasOverflow] = useState(false);
    const { width } = useWindowResize();

    useEffect(() => {
        if (tagRowRef.current && 24 < tagRowRef.current?.scrollHeight) {
            setHasOverflow(true);
        } else {
            setHasOverflow(false);
        }
    }, [sources.length, setHasOverflow, width]);

    const handleClick = (index: number) => {
        setSourceIndex(index);
        setAnchorIndex(index);
        if (!isOpen) {
            onToggle();
        }
    };
    return (
        <Box
            color={'border-alt'}
            marginTop={'8px'}
            marginBottom={'12px'}
            borderTopWidth={'1px'}
            borderColor={'border-main'}
            paddingTop={'8px'}
            fontSize={'0.8em'}
            alignItems={'center'}
        >
            <Popover initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onClose} placement="bottom">
                <Flex alignItems={'center'}>
                    <HStack
                        ref={tagRowRef}
                        flex={1}
                        overflow={'hidden'}
                        maxHeight={showAll ? undefined : '20px'}
                        flexWrap={'wrap'}
                        rowGap={'12px'}
                    >
                        <Text fontWeight={'bold'}>Source{sources.length > 1 ? 's' : ''}:</Text>
                        {sources.map((source, i) => (
                            <TagAnchor
                                source={source}
                                index={i}
                                key={i}
                                onClick={() => handleClick(i)}
                                isAnchor={anchorIndex === i}
                            />
                        ))}
                        {hasOverflow && showAll && (
                            <Button
                                padding={'0 12px'}
                                onClick={() => setShowAll((v) => false)}
                                textTransform={'none'}
                                size={'xs'}
                                variant={'unstyled'}
                            >
                                Show less
                            </Button>
                        )}
                    </HStack>
                    {hasOverflow && !showAll && (
                        <Button
                            padding={'0 12px'}
                            onClick={() => setShowAll((v) => true)}
                            textTransform={'none'}
                            size={'xs'}
                            variant={'unstyled'}
                        >
                            Show all
                        </Button>
                    )}
                </Flex>
                <PopoverContent color="text-secondary" bg="modal-background" borderColor="modal-background">
                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                        <Box>
                            Source document:{' '}
                            {!safeUrl(sources[sourceIndex].source_document) ? (
                                sources[sourceIndex].source_document
                            ) : (
                                <Link href={sources[sourceIndex].source_document} isExternal>
                                    {sources[sourceIndex].source_document} <ExternalLinkIcon mx="2px" />
                                </Link>
                            )}
                        </Box>
                        <Box>
                            {sources[sourceIndex].section_title &&
                                `Section title: ${sources[sourceIndex].section_title}`}
                        </Box>
                    </PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody whiteSpace={'pre-wrap'} maxHeight={'300px'} overflow={'auto'}>
                        {sources[sourceIndex].text.replace(/\r\n|\r|\n/g, '\n')}
                    </PopoverBody>
                    <PopoverFooter border="0" display="flex" alignItems="center" justifyContent="space-between" pb={4}>
                        <Box fontSize="sm">
                            {sourceIndex + 1} of {sources.length}
                        </Box>
                        <ButtonGroup size="sm">
                            <Button
                                size={'xs'}
                                variant={'outline'}
                                onClick={() => setSourceIndex((i) => (i - 1 + sources.length) % sources.length)}
                            >
                                Previous
                            </Button>
                            <Button
                                size={'xs'}
                                variant={'outline'}
                                ref={initialFocusRef}
                                onClick={() => setSourceIndex((i) => (i + 1) % sources.length)}
                            >
                                Next
                            </Button>
                        </ButtonGroup>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
        </Box>
    );
}

const TagAnchor = ({
    onClick,
    isAnchor,
    index,
    source,
}: {
    onClick: () => void;
    isAnchor: boolean;
    index: number;
    source: DocSource;
}) => {
    let title = source.text;
    const url = safeUrl(source.source_document);
    if (url) {
        title = url.hostname;
        if (title.startsWith('www.')) {
            title = title.slice(4);
        }
    }
    const tag = (
        <Tag
            whiteSpace={'nowrap'}
            cursor={'pointer'}
            onClick={onClick}
            background={'white15'}
            color={'border-alt'}
            size={'sm'}
            w={'20%'}
            maxW={'120px'}
            minW={'80px'}
            overflow={'hidden'}
            display={'block'}
            lineHeight={'20px'}
            textOverflow={'ellipsis'}
        >
            {index + 1}. {title.slice(0, 30)}
        </Tag>
    );
    return isAnchor ? <PopoverAnchor>{tag}</PopoverAnchor> : tag;
};

function safeUrl(str: string) {
    try {
        return new URL(str);
    } catch (_) {
        return false;
    }
}

function useWindowResize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
}
