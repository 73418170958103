import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-light.css';
import React, { useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsCheckCircle, BsClipboard } from 'react-icons/bs';

import { Box, Code, IconButton, Text } from '@chakra-ui/react';
import { captureException } from '@sentry/nextjs';

export function CodeRenderer({
    message,
    isLoading,
    onCopy,
}: {
    message?: string;
    isLoading?: boolean;
    onCopy?: (code: string) => void;
}) {
    const [copied, setCopied] = useState(false);
    const ref = useRef<number>(null);
    const lines = message?.split('\n');
    let insideCodeBlock = false;
    let code = '';
    let language: string;

    const animateCopied = (code: string) => () => {
        setCopied(true);
        if (ref.current !== null) {
            clearTimeout(ref.current);
        }
        // @ts-ignore
        ref.current = setTimeout(() => {
            setCopied(false);
            // @ts-ignore
            ref.current = null;
        }, 1500);
        if (onCopy) onCopy(code);
    };

    return (
        <Box>
            {lines?.map((line, index) => {
                if (line.startsWith('```')) {
                    insideCodeBlock = !insideCodeBlock;
                    if (insideCodeBlock) {
                        language = line?.slice(3) || '';
                        code = '';
                        return null;
                    } else {
                        let highlightedCode = code;
                        try {
                            if (language) {
                                highlightedCode = hljs?.highlight(code, { language }, true).value;
                            }
                        } catch (e) {
                            // This logs too much find a way to sample this instead
                            // captureException(e); // Logs the error
                        }
                        return (
                            <Box
                                key={index}
                                borderWidth={1}
                                maxW={'600px'}
                                borderColor={'background-secondary'}
                                bg={'background-secondary'}
                                rounded="md"
                                shadow="md"
                            >
                                <Box
                                    px={'16px'}
                                    py={'4px'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                >
                                    <Text
                                        textStyle={'body-12'}
                                        fontSize={'14px'}
                                        color={'text-primary'}
                                        lineHeight={'26px'}
                                        whiteSpace="pre-line"
                                    >
                                        {language}
                                    </Text>
                                    {!isLoading && (
                                        <CopyToClipboard text={code} onCopy={animateCopied(code)}>
                                            <IconButton
                                                border={'none'}
                                                aria-label={'copy code'}
                                                size={'xs'}
                                                icon={copied ? <BsCheckCircle /> : <BsClipboard />}
                                            ></IconButton>
                                        </CopyToClipboard>
                                    )}
                                </Box>
                                <Code
                                    whiteSpace="pre-wrap"
                                    fontSize="sm"
                                    bg={'background-main'}
                                    color={'text-primary'}
                                    p={4}
                                    w={'100%'}
                                    borderBottomRadius={'md'}
                                    className={`language-${language}`}
                                    dangerouslySetInnerHTML={{ __html: highlightedCode }}
                                />
                            </Box>
                        );
                    }
                } else if (insideCodeBlock) {
                    code += line + '\n';
                    return null;
                } else {
                    return (
                        <Text
                            textStyle={'body-18'}
                            color={'text-primary'}
                            lineHeight={'26px'}
                            whiteSpace="pre-line"
                            mb={'10px'}
                            key={index}
                        >
                            {line}
                            {isLoading && index === lines.length - 1 && (
                                <Text className="cursor" fontWeight={'bold'}>
                                    |
                                </Text>
                            )}
                        </Text>
                    );
                }
            })}
        </Box>
    );
}
