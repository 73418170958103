import { useDispatch, useSelector } from 'react-redux';

import {
    IRequestOptionState,
    resetRequestOption,
    setRequestOption,
    setRetrievalDataset,
} from '../slices/request-option-slice';
import { RootState } from '../store';

export const useRequestOptionStore = () => {
    const dispatch = useDispatch();
    const requestOption = useSelector((state: RootState) => state.requestOption);

    const setRequestOptionAction = (payload: IRequestOptionState) => {
        dispatch(setRequestOption(payload));
    };

    const resetRequestOptionAction = () => {
        dispatch(resetRequestOption());
    };

    const setRetrievalDatasetAction = (payload: string) => {
        dispatch(setRetrievalDataset(payload));
    };

    return {
        requestOption,
        setRetrievalDatasetAction,
        setRequestOptionAction,
        resetRequestOptionAction,
    };
};
