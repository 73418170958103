import cx from 'classnames';
import katex from 'katex';
import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { visit } from 'unist-util-visit';

import { Box } from '@chakra-ui/react';
import { captureException } from '@sentry/nextjs';

import { BlinkingCursor } from './blinker-cursor';
import { CodeRenderer } from './code-renderer';
import styles from './text-item.module.css';

const blinkingCursorPlugin = () => (tree: any) => {
    visit(tree, 'root', (node) => {
        if (!node || !node?.children?.length) return;

        let lastChild = node.children.at(-1);
        while (lastChild.type !== 'paragraph' && lastChild.children?.length) {
            lastChild = lastChild.children.at(-1);
        }

        if (lastChild.type === 'code') {
            return;
        }
        lastChild.children ??= [];
        lastChild.children.push({ type: 'html', value: <span className={'cursor'}>|</span> });
    });
};

const TextItem = ({
    content,
    isLoading,
    handleCodeCopy,
}: {
    content: string;
    isLoading: boolean;
    handleCodeCopy?: (code: string) => void;
}) => {
    content = useMemo(() => {
        return safeMathTransform(content);
    }, [content]);
    return (
        <Box width={'100%'} lineHeight={'28px'} className={styles.markdown}>
            <ReactMarkdown
                remarkPlugins={isLoading ? [remarkGfm, [remarkMath, { singleDollarTextMath: false }], blinkingCursorPlugin] : [remarkGfm, [remarkMath, { singleDollarTextMath: false }]]}
                rehypePlugins={[rehypeKatex as any]}
                components={{
                    p: ({ className, children }) => <p className={cx(className, styles.paragraph)}>{children}</p>,
                    code: ({ node, inline, className, children, ...props }) => {
                        // @ts-ignore
                        const match = /language-(\w+)/.exec(className || '');
                        const language = match ? match[1] : '';
                        if (inline) {
                            return (
                                <code {...props} className={cx(className, styles.inlineCode)}>
                                    `{children}`
                                </code>
                            );
                        }
                        return (
                            <CodeRenderer
                                onCopy={handleCodeCopy}
                                message={'```' + language + '\n' + String(children).replace(/\n$/, '') + '\n```'}
                                isLoading={isLoading}
                            ></CodeRenderer>
                        );
                    },
                    a: ({ className, href, children }) => (
                        <a
                            className={cx(className, styles.anchor)}
                            href={href}
                            rel="noopener noreferrer nofollow"
                            target="_blank"
                        >
                            {children}
                        </a>
                    ),
                }}
            >
                {content}
            </ReactMarkdown>
        </Box>
    );
};

export default TextItem;

// Transform content to be compatible with katex math plugins which only support $$ notation
export function safeMathTransform(content: string) {
    try {
        const inlineStore: string[] = [];
        const blockStore: string[] = [];
        const inlineKey = `|${String(Math.random())}|`;
        const blockKey = `|${String(Math.random())}|`;
        const convertIfValid = (match: string, group: string) => {
            if (testKatex(group)) {
                return `$$${group}$$`;
            } else {
                return group;
            }
        };
        return content
            .replaceAll(/`(.*?)`/g, (match) => {
                // we want to hide away all the code blocks to simplify \( and \[ regex
                inlineStore.push(match);
                return inlineKey;
            })
            .replaceAll(/```(?:\n|.)*?```/g, (match) => {
                // same but for ``` blocks
                blockStore.push(match);
                return blockKey;
            })
            .replaceAll(/\\\((.*?)\\\)/gm, convertIfValid) // finds \(
            .replaceAll(/\\\[(\s*[\s\S]*?\s*)\\\]/gm, convertIfValid) // finds \[
            .replaceAll(inlineKey, () => {
                // restores the hidden code blocks
                return inlineStore.shift() || '';
            })
            .replaceAll(blockKey, () => {
                return blockStore.shift() || '';
            });
    } catch (e) {
        captureException(e);
        return content;
    }
}

function testKatex(expr: string) {
    try {
        katex.renderToString(expr);
    } catch (e) {
        return false;
    }
    return true;
}
